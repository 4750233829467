import React from 'react'

import Layout from "../components/Layout"





const AboutPage  = () => (
  <Layout seoProps={{title: "About"}}>
      <div >

      </div>
  </Layout>
)

export default AboutPage